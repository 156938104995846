import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import VideoPlayer from '../../components/video'
import SectionHeader from '../../components/sectionHeader'
import InnerWrapper from '../../elements/innerWrapper'
import SectionTransition from '../../elements/activeSectionDefault'
import { breakpoint, getColor } from '../../shared/theme'

const VideoWrapper = styled(SectionTransition)`
	position: relative;
	width: 100%;
	color: ${getColor('white')};

	&:last-child {
		margin-bottom: 0;
		padding-bottom: 0;
	}

	&::before {
		content: '';
		width: 100%;
		position: relative;
		display: block;
		padding-top: 100vh;

		@media ${breakpoint('tablet')} {
			padding-top: 56%;
		}
	}
`

const VideoHeader = styled.div`
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	padding-top: 48px;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 200%;
		background: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0.2) 0%,
			rgba(0, 0, 0, 0) 100%
		);
	}

	@media ${breakpoint('tablet')} {
		padding-top: 80px;
	}

	@media ${breakpoint('desktop')} {
		padding-top: 92px;
	}
`

const VideoTitle = styled(SectionHeader)`
	text-transform: uppercase;

	h6 {
		display: inline-block;
		position: relative;

		&::after {
			content: '';
			position: absolute;
			bottom: -5px;
			left: 0;
			width: 30px;
			height: 1px;
			background-color: ${getColor('white')};

			@media ${breakpoint('tablet')} {
				display: none;
			}
		}
	}
`

const VideoBlock = ({
	desktopMp4,
	mobileMp4,
	desktopWebm,
	mobileWebm,
	image,
	videoSectionTitle,
	isActive,
}) => {
	const video = {
		desktopMp4,
		mobileMp4,
		desktopWebm,
		mobileWebm,
	}

	if (video == null || image == null) {
		return null
	}

	return (
		<VideoWrapper isActive={isActive} classname="with-background module">
			<VideoPlayer image={image} video={video} />
			<VideoHeader>
				<InnerWrapper>
					<VideoTitle as="div">
						<h6>{videoSectionTitle}</h6>
					</VideoTitle>
				</InnerWrapper>
			</VideoHeader>
		</VideoWrapper>
	)
}

export default VideoBlock
